<template>
  <div class="page">
    <div class="search-box section">
      <el-form
          ref="query"
          :inline="true"
          :model="query"
          class="demo-form-inline"
          size="medium"
      >
        <el-form-item prop="parkingLotInfoId">
          <SuggestParking
              v-model="query.parkingLotInfoId"
              style="width: 300px"
          />
        </el-form-item>
        <el-form-item prop="parkingOrderType">
          <el-select
              v-model="query.parkingOrderType"
              clearable
              placeholder="请选择-订单类型"
              style="width: 300px"
          >
            <el-option label="临停" value="0"></el-option>
            <el-option label="长租" value="1"></el-option>
            <el-option label="白名单" value="2"></el-option>
            <el-option label="黑名单" value="3"></el-option>
            <el-option label="预存" value="4"></el-option>
            <el-option label="访客" value="5"></el-option>
            <el-option label="共享" value="6"></el-option>
            <el-option label="预约" value="7"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="VehicleId">
          <NumplateSuggest v-model="query.VehicleId" style="width: 260px"/>
        </el-form-item>
        <el-form-item prop="phoneNumber">
          <el-input
              v-model="query.phoneNumber"
              clearable
              placeholder="请输入用户手机号码"
              prefix-icon=""
              style="width: 300px"
          ></el-input>
        </el-form-item>
        <el-form-item prop="parkingTypeTime">
          <div class="xxd-input__wrap">
            <el-select
                v-model="query.parkingTypeTime"
                clearable
                placeholder="时间范围"
            >
              <el-option label="驶入时间" value="1"></el-option>
            </el-select>
            <el-date-picker
                v-model="query.timeValues"
                align="right"
                end-placeholder="结束日期"
                range-separator="至"
                start-placeholder="开始日期"
                type="datetimerange"
                value-format="yyyy-MM-dd HH:mm:ss"
            >
            </el-date-picker>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="doSearch">搜索</el-button>
          <el-button @click="resetQueryForm">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="content-box section">
      <div class="op-box">
        <div class="op-content">
          共<span class="total-size">{{ pagination.totalSize }}</span
        >条信息
        </div>
      </div>
      <div>
        <el-table
            v-loading="isLoading"
            :data="dataList"
            border
            class="common-table"
            size="medium"
            style="width: 100%"
        >
          <el-table-column align="center" label="车牌号码" width="200">
            <template slot-scope="scope">
              <Numplate :type="scope.row.plateColor">{{
                  scope.row.plateNumber
                }}
              </Numplate>
            </template>
          </el-table-column>
          <el-table-column
              align="center"
              label="订单类型"
              prop="name"
              width="200"
          >
            <template slot-scope="scope">
              <span>{{ orderType[scope.row.orderType] }}</span>
            </template>
          </el-table-column>
          <el-table-column
              align="center"
              label="查看图片"
              prop="spaceCode"
              width="200"
          >
          </el-table-column>
          <el-table-column
              align="center"
              label="用户手机号"
              prop="spaceCode"
              width="250"
          >
          </el-table-column>
          <el-table-column
              align="center"
              label="驶入时间"
              prop="spaceCode"
          >
          </el-table-column>
          <el-table-column
              align="center"
              label="停车时长"
              prop="spaceCode"
              width="200"
          >
          </el-table-column>
          <el-table-column
              align="center"
              label="预支付金额"
              prop="spaceCode"
              width="200"
          >
          </el-table-column>
          <el-table-column
              align="center"
              label="操作"
              prop="spaceCode"
              width="200"
          >
          </el-table-column>
        </el-table>
      </div>
      <div class="pagination-box">
        <el-pagination
            :current-page="pagination.pageNo"
            :page-size="pagination.pageSize"
            :total="pagination.totalSize"
            background
            layout="sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import SuggestParking from "@/components/SuggestParking";
import NumplateSuggest from "@/components/NumplateSuggest";
import Numplate from "@/components/Numplate";
import BaseMixin from "@/mixins/base";

export default {
  name: "overtime_parking",
  mixins: [BaseMixin],
  components: {
    SuggestParking,
    NumplateSuggest,
    Numplate,
  },
  data() {
    return {
      isLoading: false,
      query: {
        parkingLotInfoId: "",
        parkingOrderType: "",
        VehicleId: "",
        phoneNumber: "",
        parkingTypeTime: "1",
        timeValues: [],
      },
      dataList: [],
    };
  },
  methods: {
    //重置查询条件
    resetQueryForm() {
      this.$refs.query.resetFields();
      this.query.timeValues = [];
      this.pagination.pageSize = 10;
      this.doSearch();
    },
    //查询
    doSearch() {
      this.pagination.pageNo = 1;
      this.search();
    },
    async search() {
    },
  },
};
</script>

<style lang="less" scoped>
</style>
